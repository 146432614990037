<template>
  <section>
    <top-alternative-section :image="FulfillmentImage" :title="$t('fulfillment.topAlternative.title')"
      :content="$t('fulfillment.topAlternative.content')"></top-alternative-section>

    <features-section :title="$t('fulfillment.features.title')" :description="$t('fulfillment.features.description')" 
      :items="$t('fulfillment.features.items')" ></features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import FulfillmentImage from "../../assets/fulfillment.jpg";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";

export default {
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      FulfillmentImage: FulfillmentImage,
    };
  }, 
  metaInfo: {
    title: 'Fulfillment',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Integra procesos de distribución y fulfillment, garantizando que los productos lleguen a tiempo y en buenas condiciones a los clientes.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
};
</script>
